@import 'material';

body {
  margin: 0;
  font-family: 'Nunito', 'Helvetica Neue', sans-serif;
}

div.logo {
  background-position: center center;
  height: 100px;
  width: 600px;
}

@media only screen and (min-width: 600px) {
  body {
    background-color: #f9f9f9;
  }

  html,
  body {
    height: 100%;
  }
}

@media only screen and (max-width: 600px) {
  button.main-button {
    height: 60px;
    font-size: 1.1rem !important;
  }

  div.logo {
    width: auto;
  }
}
