@use '@angular/material' as mat;
@include mat.core();

$default-palette: (
  50: #fcf3f3,
  100: #f9e0e0,
  200: #f5cccc,
  300: #f0b8b8,
  400: #eda8a8,
  500: #ea9999,
  600: #e79191,
  700: #e48686,
  800: #e17c7c,
  900: #db6b6b,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$fr-palette: (
  50: #fef2de,
  100: #fdddad,
  200: #fcc778,
  300: #fab041,
  400: #f99f0d,
  500: #f88f00,
  600: #f48300,
  700: #ef7200,
  800: #e96200,
  900: #e04500,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$fimm-palette: (
  50: #e1efeb,
  100: #b4d8ce,
  200: #83bead,
  300: #51a48c,
  400: #2b9173,
  500: #067d5a,
  600: #057552,
  700: #046a48,
  800: #03603f,
  900: #024d2e,
  A100: #80ffc1,
  A200: #4dffa8,
  A400: #1aff8f,
  A700: #01ff82,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$default-typography: mat.define-typography-config(
  $font-family: 'Nunito',
);

$default-primary: mat.define-palette($default-palette);
$fr-primary: mat.define-palette($fr-palette);
$fimm-primary: mat.define-palette($fimm-palette);

$default-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);
$default-warn: mat.define-palette(mat.$red-palette);

$default-theme: mat.define-light-theme(
  (
    color: (
      primary: $default-primary,
      accent: $default-accent,
      warn: $default-warn,
    ),
    typography: $default-typography,
  )
);

$fr-theme: mat.define-light-theme(
  (
    color: (
      primary: $fr-primary,
      accent: $default-accent,
      warn: $default-warn,
    ),
    typography: $default-typography,
  )
);

$fimm-theme: mat.define-light-theme(
  (
    color: (
      primary: $fimm-primary,
      accent: $default-accent,
      warn: $default-warn,
    ),
    typography: $default-typography,
  )
);

.default-theme {
  @include mat.all-component-themes($default-theme);

  .mat-button-toggle-checked,
  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    background-color: mat.get-color-from-palette($default-palette, 500);
    color: mat.get-contrast-color-from-palette($default-palette, 500);
  }

  div.logo {
    background-image: url('assets/logo_default.png');
  }
}

.fr-theme {
  @include mat.all-component-themes($fr-theme);

  .mat-button-toggle-checked,
  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    background-color: mat.get-color-from-palette($fr-palette, 500);
    color: mat.get-contrast-color-from-palette($fr-palette, 500);
  }

  div.logo {
    background-image: url('assets/logo_facile_ristrutturare_chiara_2_w600_h100.jpg');
  }
}

.fimm-theme {
  @include mat.all-component-themes($fimm-theme);

  .mat-button-toggle-checked,
  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    background-color: mat.get-color-from-palette($fimm-palette, 500);
    color: mat.get-contrast-color-from-palette($fimm-palette, 500);
  }

  div.logo {
    background-image: url('assets/fimm_logo.jpg');
  }
}

// .primary-color-bg {
//   background-color: mat.get-color-from-palette($fr-palette, 600);
//   color: mat.get-contrast-color-from-palette($fr-palette, 600);
// }
